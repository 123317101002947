import Head from 'next/head'
import React, { useState } from 'react'

import { ResponsiveImage } from '@syconium/little-miss-figgy'
import { usePageSectionContext } from '@syconium/magnolia/src/components/page-sections/PageSectionContext'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'

import { MaybeSpaLinkWrapper } from '../../../lib/adapters/next-routing-service/MaybeSpaLinkWrapper'
import { useResponsiveVideoSrc } from '../../../lib/hooks/useResponsiveVideoSrc'
import { CollectionViewHeroSection as CollectionViewHeroSectionQueryProps } from '../../../types/graphql'
import { InlineVideoPlatforms } from '../../../types/video'
import { useFixturesContext } from '../../containers/fixtures'
import { VideoModal } from '../VideoModal'
import { VimeoVideo } from '../VimeoVideo'

import {
  AdditionalContentWrapper,
  Container,
  Cta,
  ImageWrap,
  ModalVideoPlayButton,
  Subtitle,
  SubtitleWrapper,
  TextWrap,
  Title,
  Video,
} from './styles'

export const HeroImage: React.FC<{
  imageDesktop: string | null
  imageMobile: string | null
  title: string
  isCriticalImage: boolean
}> = ({ imageDesktop, imageMobile, title, isCriticalImage }) => {
  const imageSrc = imageDesktop ?? imageMobile

  if (!imageSrc) return null

  const srcs =
    imageDesktop && imageMobile
      ? {
          sm: imageMobile,
          md: imageDesktop,
        }
      : undefined

  return (
    <ResponsiveImage
      src={imageSrc}
      alt={title}
      aspectRatios={{
        sm: null,
        md: null,
      }}
      loading={isCriticalImage ? 'default' : 'lazy'}
      fetchpriority={isCriticalImage ? 'high' : undefined}
      widths={{ unit: 'vw', sm: 100, md: 100 }}
      srcs={srcs}
      renderPreloadLink={
        isCriticalImage
          ? preloadLink => {
              return <Head>{preloadLink}</Head>
            }
          : undefined
      }
    />
  )
}

type CollectionViewHeroSectionProps = CollectionViewHeroSectionQueryProps & {
  additionalContent?: React.ReactNode
  isCriticalImage: boolean
}

export const CollectionViewHeroSection = ({
  backgroundColor,
  ctaLink,
  ctaText,
  imageDesktop,
  imageMobile,
  imagePlacement,
  inlineVideoDesktop,
  inlineVideoMobile,
  inlineVimeoIdDesktop,
  inlineVimeoIdMobile,
  subtitle,
  additionalContent,
  textColor,
  title,
  videoId,
  videoPlatform,
  isCriticalImage,
  name,
}: CollectionViewHeroSectionProps) => {
  const hasMediaDesktop = !!(imageDesktop || inlineVideoDesktop)
  const hasModalVideo = !!(videoId && videoPlatform)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { pageSectionIndex: pageSectionIndex } = usePageSectionContext()
  const {
    magnolia: { general },
    collections: {
      heroSection: { playVideo },
    },
  } = useFixturesContext()

  const video = useResponsiveVideoSrc({
    inlineVideoDesktop,
    inlineVideoMobile,
    inlineVimeoIdDesktop,
    inlineVimeoIdMobile,
  })

  return (
    <Container
      data-testid='hero-section'
      backgroundColor={backgroundColor}
      hasMediaDesktop={hasMediaDesktop}
      textColor={textColor}
    >
      <TextWrap hasMediaDesktop={hasMediaDesktop}>
        {title && <Title>{title}</Title>}
        {subtitle && (
          <SubtitleWrapper>
            <Subtitle>{subtitle}</Subtitle>
          </SubtitleWrapper>
        )}

        {/* Optionally render arbitrary content in the text section */}
        {additionalContent && (
          <AdditionalContentWrapper>{additionalContent}</AdditionalContentWrapper>
        )}

        {ctaLink && ctaText && (
          <MaybeSpaLinkWrapper href={ctaLink}>
            <Cta
              onClick={() => null}
              {...trackEvent({
                category: 'CollectionViewHeroSection',
                action: 'click',
                label: ctaText,
                pageSectionIndex,
                pageSectionName: title ?? undefined,
                correspondingAsset: imageDesktop ?? undefined,
                contentfulName: name ?? undefined,
              })}
              as='a'
              href={ctaLink}
            >
              {ctaText}
            </Cta>
          </MaybeSpaLinkWrapper>
        )}
      </TextWrap>
      {(imageDesktop || inlineVideoDesktop) && (imageMobile || inlineVideoMobile) && (
        <ImageWrap imagePlacement={imagePlacement}>
          {!video && title && (
            <HeroImage
              imageMobile={imageMobile}
              imageDesktop={imageDesktop}
              title={title}
              isCriticalImage={isCriticalImage}
            />
          )}

          {video?.platform === InlineVideoPlatforms.DEFAULT_PLAYER && (
            <Video src={video?.src} autoPlay muted playsInline loop />
          )}

          {video?.platform === InlineVideoPlatforms.VIMEO && title && (
            <VimeoVideo
              playText={general.playVideo}
              pauseText={general.pauseVideo}
              autoplay
              thumbnail={
                <HeroImage
                  imageMobile={imageMobile}
                  imageDesktop={imageDesktop}
                  title={title}
                  isCriticalImage={isCriticalImage}
                />
              }
              videoSrc={video.src}
            />
          )}

          {hasModalVideo && (
            <ModalVideoPlayButton
              {...trackEvent({
                category: 'collection-view-hero-section',
                action: 'open hero video modal',
                label: videoPlatform ?? '',
                value: videoId ?? '',
                pageSectionIndex,
                pageSectionName: title ?? undefined,
                correspondingAsset: imageDesktop ?? undefined,
                contentfulName: name ?? undefined,
              })}
              onClick={() => setIsModalOpen(true)}
              aria-label={playVideo}
            />
          )}
        </ImageWrap>
      )}
      {hasModalVideo && (
        <VideoModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          videoPlatform={videoPlatform}
          videoId={videoId}
        />
      )}
    </Container>
  )
}
