import React from 'react'

import { CenterText } from '@syconium/magnolia/src/brunswick/components/atoms/CenterText'
import { usePageSectionContext } from '@syconium/magnolia/src/components/page-sections/PageSectionContext'
import { trackEvent } from '@syconium/magnolia/src/lib/analytics'
import { isUppercase } from '@syconium/magnolia/src/lib/utils'

import { MaybeSpaLink } from '../../../../../../lib/adapters/next-routing-service/MaybeSpaLink'
import {
  PageTabTileSection,
  PageTabTileSectionTile as Props,
} from '../../../../../../types/graphql'

import { Body, Figcaption, PageTabTileImage } from './styles'

export const PageTabTileSectionTile: React.FC<
  Props & Pick<PageTabTileSection, 'tileVariantDesktop' | 'tileVariantMobile'>
> = ({
  ctaText,
  href,
  image,
  name,
  tileVariantDesktop,
  tileVariantMobile,
  visibleTilesDesktop,
  visibleTilesMobile,
}) => {
  const [action, category, label] = ['click tile', 'PageTabSection', ctaText]
  const letterSpacing = isUppercase(ctaText) ? '0.15em' : '0.05em' // per TypeStyle fixtures

  const { pageSectionIndex: pageSectionIndex } = usePageSectionContext()

  return (
    <Body tileVariantDesktop={tileVariantDesktop} tileVariantMobile={tileVariantMobile}>
      <MaybeSpaLink
        href={href}
        tabIndex={-1}
        {...trackEvent({
          action,
          category,
          label,
          value: 'image',
          pageSectionName: name,
          pageSectionIndex,
          contentfulName: name,
        })}
      >
        <PageTabTileImage
          tileVariantDesktop={tileVariantDesktop}
          tileVariantMobile={tileVariantMobile}
          alt={ctaText}
          aspectRatios={{
            sm: 1,
            md: 1,
          }}
          src={image}
          loading='lazy'
          widths={{
            unit: 'vw',
            sm: 100 / visibleTilesMobile,
            md: 100 / visibleTilesDesktop,
          }}
        />
      </MaybeSpaLink>
      <Figcaption isUppercase={isUppercase(ctaText)}>
        <CenterText {...{ letterSpacing }}>
          <MaybeSpaLink
            {...{ href }}
            {...trackEvent({
              action,
              category,
              label,
              value: 'text',
              pageSectionName: name,
              pageSectionIndex,
              contentfulName: name,
            })}
          >
            {ctaText}
          </MaybeSpaLink>
        </CenterText>
      </Figcaption>
    </Body>
  )
}
